// ProjectsPage.js
import React from 'react';
import NavBar from '../components/NavBar';
import ProjectCard from '../components/ProjectCard';

function ProjectsPage() {
  return (
    <div>
      {NavBar()}
      {ProjectCard()}
    </div>
  );
}

export default ProjectsPage;
