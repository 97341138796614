import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function ProjectCard() {
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getAllProj`);
      const data = await response.json();
      setProjectData(data);
    };

    fetchData();
  }, []);

  if (projectData === null) {
    return <p className="text-white">Loading...</p>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-2 gap-y-4 px-4 md:px-0 mx-auto justify-items-center">
      {projectData.map((project) => (
        <div key={project._id} className="w-full lg:max-w-card-lg flex flex-col bg-dark-blue rounded overflow-hidden shadow-md">
          <div className="h-64 w-full overflow-hidden relative">
            <div className="w-full h-full absolute">
              <LazyLoadImage
                alt={project.name}
                src={`https://storage.googleapis.com/danielwang.xyz/image/${encodeURIComponent(project.imgPath)}`}
                effect="blur"
                wrapperClassName="w-full h-full"
                className="object-cover h-full w-full"
              />
            </div>
          </div>
          <div className="flex-grow p-6">
            <div className="font-bold text-xl mb-2 text-slate-200">{project.full_name}</div>
            <div className="font-bold text-xs sm:text-sm mb-2 text-slate-200">{project.name}</div>
            <p className="text-blue-200 text-base">
              {project.description}
            </p>
          </div>
          <div className="px-6 py-4">
            <span className="inline-block bg-light-blue rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">{project.date}</span>
            <div className="mt-4">
              {project.category.map((category, index) => (
                <span key={index} className="inline-block bg-light-green rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{category}</span>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProjectCard;
