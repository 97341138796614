// ContactPage.js
import React from 'react';
import NavBar from '../components/NavBar';

function ContactPage() {
  return (
    <div>
      {NavBar()}
      <h1>Contact Page</h1>
    </div>
  );
}

export default ContactPage;
