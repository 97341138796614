import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function HomeProjects() {
  return (
    <div className="flex-col space-y-4 h-fit mt-36">
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 h-1/3">
        <div className="w-full sm:w-1/2 bg-transparent">
          <LazyLoadImage 
            src="https://storage.googleapis.com/danielwang.xyz/image/%E5%B0%88%E9%A1%8C%E5%90%88%E7%85%A7.JPG"
            alt="Project 1" 
            className="object-cover rounded-3xl" 
            effect="blur"
          />
        </div>
        <div className="w-full sm:w-1/2 p-4 flex flex-col justify-center">
          <p className="text-white font-mono text-5xl sm:mt-14">畢業專題</p>
          <p className="text-white font-mono text-3xl">智能共享單車管理系統</p>
          <p className="text-white font-mono text-xl">Intelligent Bike Sharing Management System</p>
          <div className="flex justify-center mt-4 sm:mt-20">
          {/* <a href="https://ibsms.danielwang.xyz/" target="_blank" rel="noopener noreferrer" className="text-black">
              <button className="rounded-full bg-blue-200 w-32 h-10">瞭解更多</button>
          </a> */}
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 h-1/3">
        <div className="w-full sm:w-1/2 bg-transparent flex justify-center items-center">
          <LazyLoadImage 
            src="https://storage.googleapis.com/danielwang.xyz/image/2023_Yilan.jpeg"
            alt="Project 2" 
            className="object-cover rounded-3xl" 
            effect="blur"
          />
        </div>
        <div className="w-full sm:w-1/2 p-4 flex flex-col justify-center">
          <p className="text-white font-zmg text-5xl sm:mt-14">與朋友旅行～</p>
          <p className="text-white font-zmg text-3xl">宜蘭 Yilan</p>
          <p className="text-white font-zmg text-xl">2023/07/10 ~ 2023/07/13</p>
        </div>
      </div>
      
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 h-1/3">
        <div className="w-full sm:w-1/2 bg-transparent flex justify-center items-center">
        <LazyLoadImage 
            src="https://storage.googleapis.com/danielwang.xyz/image/NKUST-CES.png"
            alt="Project 3" 
            className="object-cover rounded-3xl" 
            effect="blur"
          />
        </div>
        <div className="w-full sm:w-1/2 p-4 flex flex-col justify-center">
          <p className="text-white font-zas text-4xl sm:mt-14">自動化填寫高科大期末調查問卷</p>
          <p className="text-white font-zas text-3xl">使用Selenium</p>
          <div className="flex justify-center mt-4 sm:mt-20">
            <a href="https://github.com/DanielWang2002/EasyCES" target="_blank" rel="noopener noreferrer" className="text-black">
              <button className="rounded-full bg-blue-200 w-40 h-10">點我查看GitHub</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeProjects;
