import { Link, useLocation } from 'react-router-dom';
import '../css/NavBar.css'; // 导入外部 CSS 文件

function NavBar() {
  const location = useLocation();
  
  return (
    <nav className='navbar navbar-expand-lg navbar-light'>
      <div className='container-fluid'>
        <a className='navbar-brand' href='/'>DW</a>
        <div className="flex w-full pt-2 content-center justify-between md:w-1/2 md:justify-end">
          <ul className='list-reset flex justify-between flex-1 md:flex-none items-center'>
            <li className='mr-3'>
              <Link className={`inline-block py-2 px-4 ${location.pathname === '/' ? 'text-white' : 'text-gray-400 hover:text-gray-200'} no-underline`} to='/'>Home</Link>
            </li>
            <li className='mr-3'>
              <Link className={`inline-block py-2 px-4 ${location.pathname === '/projects' ? 'text-white' : 'text-gray-400 hover:text-gray-200'} no-underline`} to='/projects'>Projects</Link>
            </li>
            {/* <li className='mr-3'>
              <Link className={`inline-block py-2 px-4 ${location.pathname === '/contact' ? 'text-white' : 'text-gray-400 hover:text-gray-200'} no-underline`} to='/contact'>Contact</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
