// HomePage.js
import React from 'react';

import HomeCenterContent from '../components/HomeCenterContent';
import NavBar from '../components/NavBar';

function HomePage() {
  return (
    <div>
      {NavBar()}
      {HomeCenterContent()}
    </div>
  );
}

export default HomePage;
