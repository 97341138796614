import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import HomePage from "./pages/Home";
import ProjectsPage from "./pages/Projects";
import ContactPage from "./pages/Contact";

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// 分頁
import { BrowserRouter as Router,Route,Switch } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <Switch>
          <Route exact={true} path={'/'}>
              <HomePage />
          </Route>
          
          <Route path={'/projects'}>
              <ProjectsPage />
          </Route>

          <Route path={'/contact'}>
              <ContactPage />
          </Route>
        </Switch>
    </Router>
)