import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import HomeProjects from './HomeProjects';

function HomeCenterContent() {
  return (
    <div className="container relative mt-12">
      <div className="flex sm:flex-row flex-col justify-around items-center">
        <div className="w-full sm:w-1/3 flex justify-center">
          <img src="https://storage.googleapis.com/danielwang.xyz/image/avater2.jpeg" className="w-64 h-64 rounded-full" alt='profileIamge'></img>
        </div>
        <div className="w-full sm:w-1/2 bg-light-blue p-8 sm:p-12 rounded-lg shadow-md flex flex-col items-center justify-center gap-5 mt-6 sm:mt-0 opacity-75">
          <h1 className="font-alkatra">Daniel Wang</h1>
          <p className="font-roboto-condensed text-2xl">Student Developer</p>
          <p className="font-roboto-condensed text-xl -mt-12">NKUST IC</p>
          <p className="font-roboto-condensed text-lg -mt-6">Mail: me@danielwang.xyz</p>
          <p className="font-roboto-condensed text-lg -mt-12">Discord: danielwang</p>
          <ul className="flex justify-center gap-6 -ml-6">
            <li><a href="https://www.facebook.com/Xiang.612/" target={'_blank'} rel={'noreferrer'} className='text-black fa-2x'><FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon></a></li>
            <li><a href="https://www.instagram.com/xiang_612/" target={'_blank'} rel={'noreferrer'} className='text-black fa-2x'><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></a></li>
            <li><a href="https://www.linkedin.com/in/danielwang2002/" target={'_blank'} rel={'noreferrer'} className='text-black fa-2x'><FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon></a></li>
            <li><a href="https://github.com/DanielWang2002" target={'_blank'} rel={'noreferrer'} className='text-black fa-2x'><FontAwesomeIcon icon={faGithub}></FontAwesomeIcon></a></li>
          </ul>
        </div>
      </div>

      <div className="container relative mt-12">
        <h1 className='mt-36 text-5xl text-cyan-100 font-zas'>你好！</h1>
        <h2 className='mt-6 text-3xl text-cyan-200 font-zas'>歡迎來到我的個人網站</h2>
        <p className='mt-4 text-lg sm:text-2xl text-cyan-200 font-zas'>我是 Daniel</p>
        <p className='mt-4 text-lg sm:text-2xl text-cyan-300 font-zas'>目前就讀於 高雄科技大學-智慧商務系</p>
        <p className='mt-4 text-lg sm:text-2xl text-cyan-300 font-zas'>對於開發網頁前後端有較高的興趣</p>
        <p className='mt-4 text-lg sm:text-2xl text-cyan-400 font-zas'>也會利用Python進行深度學習的模型訓練</p>
        <p className='mt-4 text-lg sm:text-2xl text-cyan-400 font-zas'>放假時喜歡和朋友們到處旅行</p>
        <p className='mt-4 text-lg sm:text-2xl text-cyan-500 font-zas'>在我的個人網站中</p>
        <p className='mt-4 text-lg sm:text-2xl text-cyan-500 font-zas'>你會看到我的生活紀錄與程式作品</p>
      </div>

      {HomeProjects()}
    </div>
  );
}

export default HomeCenterContent;
